<template>
  <v-layout class="fill-height overflow-y-auto pa-3" column>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="py-1" style="flex: none; width: 100%">
      <v-layout justify-end>
        <div style="width: 300px">
          <v-select
            v-model="depth"
            :items="depths"
            :loading="loading"
            dense
            hide-details
            item-text="name"
            item-value="code"
            label="Select Depth"
            outlined
            placeholder="Depth"
            @change="filterData"
          />
        </div>
      </v-layout>
    </div>
    <div>
      <v-layout align-center>
        <h4>Soil temperature</h4>
        <v-icon class="ml-12" color="#ff3636" size="32" style="rotate: 45deg">mdi-vector-line </v-icon>
        <div class="ml-3" style="font-size: 12px">Soil temp (°C)</div>
        <v-icon class="ml-8" color="#116DF2" size="32">mdi-rectangle</v-icon>
        <div class="ml-3" style="font-size: 12px">et0 (mm)</div>
      </v-layout>
    </div>
    <div
      class="my-4 pa-3"
      style="flex: none; width: 100%; min-height: 270px;height: 270px; border: 1px solid #2F3241; border-radius: 8px"
    >
      <canvas id="soilChart" style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"></canvas>
    </div>
    <div>
      <v-layout align-center>
        <h4>Soil moisture</h4>
        <v-icon class="ml-8" color="#00ff0f" size="32">mdi-rectangle</v-icon>
        <div class="ml-3" style="font-size: 12px">Soil moisture (mm)</div>
      </v-layout>
    </div>
    <div
      class="mt-4 pa-3"
      style="flex: none; width: 100%; min-height: 270px;height: 270px; border: 1px solid #2F3241; border-radius: 8px"
    >
      <canvas id="agriChart" style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"></canvas>
    </div>
  </v-layout>
</template>

<script>
import Chart from 'chart.js/auto'
import { transparentize } from '@/utils/utils'
import { getAgricultureWeather } from '@/api/weather'

export default {
  data() {
    return {
      soilChart: undefined,
      agriChart: undefined,
      depth: '01',
      depths: [
        {
          name: '0-0.1m',
          code: '01',
        },
        {
          name: '0.1-0.4m',
          code: '04',
        },
        {
          name: '0.4-1.0m',
          code: '10',
        },
        {
          name: '1.0-2.0m',
          code: '20',
        },
      ],
      labels: [],
      chartData: [],
      loading: false,
      data: [],
    }
  },
  methods: {
    async getData(filter) {
      try {
        this.loading = true
        const res = await getAgricultureWeather({
          projectId: this.$route.params.id,
          aoiId: filter.aoi.uuid,
          payload: {
            from_date: filter.dateRange[0],
            to_date: filter.dateRange[1],
          },
        })
        this.data = res.data
        this.filterData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    filterData() {
      this.data.forEach(val => {
        val.soiltemp = val[this.depth].soiltemp
        val.soilmoisture = val[this.depth].soilmoisture
        val.soilmoisturevol = val[this.depth].soilmoisturevol
      })
      if (this.soilChart)
        this.updateChart(this.data, 'soilChart', [
          { index: 'et0', color: '#116DF2', type: 'bar', label: 'et0 (mm)', yAxisID: 'y1' },
          {
            index: 'soiltemp',
            color: '#ff3636',
            type: 'line',
            tension: 0,
            radius: 0,
            fill: false,
            label: 'Soil temp (°C)',
            yAxisID: 'y',
          },
        ])
      else
        this.createChart(this.data, 'soilChart', [
          { index: 'et0', color: '#116DF2', type: 'bar', label: 'et0 (mm)', yAxisID: 'y1' },
          {
            index: 'soiltemp',
            color: '#ff3636',
            type: 'line',
            tension: 0,
            radius: 0,
            fill: false,
            label: 'Soil temp (°C)',
            yAxisID: 'y',
          },
        ])
      if (this.agriChart)
        this.updateChart(this.data, 'agriChart', [
          {
            index: 'soilmoisture',
            color: '#00ff0f',
            type: 'bar',
            label: 'Soil moisture(mm)',
            yAxisID: 'y',
          },
        ])
      else
        this.createChart(this.data, 'agriChart', [
          {
            index: 'soilmoisture',
            color: '#00ff0f',
            type: 'bar',
            label: 'Soil moisture(mm)',
            yAxisID: 'y',
          },
        ])
    },
    updateChart(data, id, indies) {
      this[id].data.labels = data.map(val => val.datetime)
      let datasets = []
      indies.forEach(item => {
        if (item.type === 'line') {
          datasets.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if (item.type === 'bar') {
          datasets.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
      this[id].data.datasets = datasets
      this[id].update()
      this[id].resize()
    },
    createChart(data, id, indies) {
      this.transformData(data, indies)
      this.displayChart(id)
    },
    transformData(data, indies) {
      this.labels = data.map(val => val.datetime)
      this.chartData = []
      indies.forEach(item => {
        if (item.type === 'line') {
          this.chartData.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if ((item.type = 'bar')) {
          this.chartData.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
    },
    displayChart(id) {
      let ctx = document.getElementById(id)
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      this[id] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index', // Set hover mode to 'nearest'
            // axis: 'x',
          },
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9,
                },
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[0].backgroundColor,
                text: this.chartData[0].label,
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: this.chartData[0].backgroundColor,
                font: {
                  size: 11,
                },
              },
            },
            y1: {
              type: 'linear',
              position: 'right',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[1] ? this.chartData[1].backgroundColor : '',
                text: this.chartData[1] ? this.chartData[1].label : '',
                display: this.chartData.length >= 2,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                display: this.chartData.length >= 2,
                color: this.chartData[1] ? this.chartData[1].backgroundColor : '',
                font: {
                  size: 11,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style scoped></style>
