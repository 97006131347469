import api from '@/api/api'
import config from '@/config'

export async function getWeather(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/historical-weather`,
    data.payload,
  )
}

export async function getForecastWeather(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/forecast-weather`, data.payload)
}

export async function getAgricultureWeather(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/historical-agriculture`,
    data.payload,
  )
}
