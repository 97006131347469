<template>
  <v-layout class="fill-height">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="fill-height" column>
      <div class="pa-2 bg-gradient-primary" style="flex: none; width: 100%; border-bottom: 1px solid #2F3241">
        <v-btn-toggle v-model="menu" borderless color="warning" mandatory>
          <v-btn min-width="175" value="temperature">
            <v-icon left>
              mdi-thermometer-alert
            </v-icon>
            <span class="hidden-sm-and-down">Temperature</span>
          </v-btn>
          <v-btn min-width="175" value="windy">
            <v-icon left>
              mdi-weather-windy
            </v-icon>
            <span class="hidden-sm-and-down">Windy</span>
          </v-btn>
          <v-btn min-width="175" value="rain">
            <v-icon left>
              mdi-weather-pouring
            </v-icon>
            <span class="hidden-sm-and-down">Precipitation</span>
          </v-btn>
          <v-btn min-width="175" value="agriculture">
            <v-icon left>
              mdi-earth-box
            </v-icon>
            <span class="hidden-sm-and-down">Agriculture</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-layout class="fill-height overflow-hidden">
        <Temp v-if="menu === 'temperature'" ref="temperature" />
        <Windy v-if="menu === 'windy'" ref="windy" />
        <Rain v-if="menu === 'rain'" ref="rain" />
        <Agriculture v-if="menu === 'agriculture'" ref="agriculture" />
      </v-layout>
    </v-layout>
  </v-layout>
</template>
<script>
import Temp from '@/views/weather/monitoring/Temp.vue'
import Windy from '@/views/weather/monitoring/Windy.vue'
import Rain from '@/views/weather/monitoring/Rain.vue'
import Agriculture from '@/views/weather/monitoring/Agriculture.vue'
import { getWeather } from '@/api/weather'
import sleep from '@/utils/sleep'

export default {
  components: { Temp, Windy, Rain, Agriculture },
  data() {
    return {
      menu: 'temperature',
      loading: false,
      filter: undefined,
      data: [],
    }
  },
  watch: {
    menu() {
      this.displayDashboard(this.filter)
    },
  },
  methods: {
    async getData(filter) {
      this.filter = filter
      try {
        this.loading = true
        const res = await getWeather({
          projectId: this.$route.params.id,
          aoiId: filter.aoi.uuid,
          payload: {
            from_date: filter.dateRange[0],
            to_date: filter.dateRange[1],
          },
        })
        this.data = [...res.data]
        this.displayDashboard()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    displayDashboard() {
      switch (this.menu) {
        case 'temperature':
          this.displayTemp()
          break
        case 'windy':
          this.displayWindy()
          break
        case 'rain':
          this.displayRain()
          break
        case 'agriculture':
          this.displayAgriculture()
          break
        default:
          this.displayTemp()
      }
    },
    async displayAgriculture() {
      await sleep(200)
      await this.$refs.agriculture.getData(this.filter)
    },
    async displayTemp() {
      await sleep(200)
      let soat = this.sumOfPrevious(this.data.map(val => val.temp))
      this.data.forEach((val, index) => [(val.soat = soat[index])])
      if (this.$refs.temperature.soatChart)
        this.$refs.temperature.updateChart(this.data, 'soatChart', [
          {
            index: 'soat',
            color: 'rgb(68 234 255)',
            tension: 0,
            radius: 0,
            fill: true,
            label: 'Temp',
          },
        ])
      else
        this.$refs.temperature.createChart(this.data, 'soatChart', [
          {
            index: 'soat',
            color: 'rgb(68 234 255)',
            tension: 0,
            radius: 0,
            fill: true,
            label: 'Temp',
          },
        ])
      if (this.$refs.temperature.dailyChart)
        this.$refs.temperature.updateChart(this.data, 'dailyChart', [
          {
            index: 'tempmin',
            color: 'rgb(68 234 255)',
            tension: 0.4,
            radius: 0,
            label: 'Temp min',
          },
          { index: 'tempmax', color: '#E040FB', tension: 0.4, radius: 0, label: 'Temp max' },
          { index: 'dew', color: '#116DF2', tension: 0.4, radius: 0, label: 'Dew point' },
        ])
      else
        this.$refs.temperature.createChart(this.data, 'dailyChart', [
          {
            index: 'tempmin',
            color: 'rgb(68 234 255)',
            tension: 0.4,
            radius: 0,
            label: 'Temp min',
          },
          { index: 'tempmax', color: '#E040FB', tension: 0.4, radius: 0, label: 'Temp max' },
          { index: 'dew', color: '#116DF2', tension: 0.4, radius: 0, label: 'Dew point' },
        ])
    },
    async displayWindy() {
      await sleep(200)
      let soat = this.sumOfPrevious(this.data.map(val => val.temp))
      this.data.forEach((val, index) => [(val.soat = soat[index])])
      if (this.$refs.windy.windyChart)
        this.$refs.windy.updateChart(this.data, 'windyChart', [
          { index: 'windgust', color: 'rgb(68 234 255)', label: 'Wind gust' },
          { index: 'windspeed', color: '#E040FB', label: 'Wind speed' },
          { index: 'pressure', color: '#116DF2', yAxisID: 'y1', label: 'Pressure' },
        ])
      else
        this.$refs.windy.createChart(this.data, 'windyChart', [
          { index: 'windgust', color: 'rgb(68 234 255)', label: 'Wind gust' },
          { index: 'windspeed', color: '#E040FB', label: 'Wind speed' },
          { index: 'pressure', color: '#116DF2', yAxisID: 'y1', label: 'Pressure' },
        ])
    },
    async displayRain() {
      await sleep(200)
      let rain = this.sumOfPrevious(this.data.map(val => val.precip))
      this.data.forEach((val, index) => [(val.precipitation = rain[index])])
      if (this.$refs.rain.rainChart)
        this.$refs.rain.updateChart(this.data, 'rainChart', [
          {
            index: 'precipitation',
            color: '#116DF2',
            type: 'line',
            tension: 0,
            radius: 0,
            fill: true,
            label: 'Precipitation',
          },
        ])
      else
        this.$refs.rain.createChart(this.data, 'rainChart', [
          {
            index: 'precipitation',
            color: '#116DF2',
            type: 'line',
            tension: 0,
            radius: 0,
            fill: true,
            label: 'Precipitation',
          },
        ])
      if (this.$refs.rain.dailyRainChart)
        this.$refs.rain.updateChart(this.data, 'dailyRainChart', [
          {
            index: 'humidity',
            color: 'rgb(68 234 255)',
            yAxisID: 'y1',
            type: 'line',
            tension: 0.4,
            radius: 0,
            label: 'Humidity',
          },
          { index: 'precip', color: '#116DF2', type: 'bar', label: 'Precipitation' },
        ])
      else
        this.$refs.rain.createChart(this.data, 'dailyRainChart', [
          {
            index: 'humidity',
            color: 'rgb(68 234 255)',
            yAxisID: 'y1',
            type: 'line',
            tension: 0.4,
            radius: 0,
            label: 'Humidity',
          },
          { index: 'precip', color: '#116DF2', type: 'bar', label: 'Precipitation' },
        ])
    },
    sumOfPrevious(arr) {
      let sum = 0
      return arr.map(num => (sum += num))
    },
  },
}
</script>

<style scoped></style>
