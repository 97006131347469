<template>
  <v-layout class="fill-height" column>
    <div style="flex: none; width: 100%">
      <v-card :loading="loading" class="AOI-menu custom-card-bg-0" width="100%">
        <v-layout align-center>
          <v-btn-toggle v-model="menu" borderless color="info" mandatory>
            <v-btn min-width="150" value="monitoring">
              <v-icon left>
                mdi-monitor-dashboard
              </v-icon>
              <span class="hidden-sm-and-down">Monitoring</span>
            </v-btn>
            <v-btn min-width="150" value="forecast">
              <v-icon left>
                mdi-weather-cloudy-alert
              </v-icon>
              <span class="hidden-sm-and-down">Forecast</span>
            </v-btn>
          </v-btn-toggle>
          <v-spacer />
          <div class="mr-1" style=" min-width: 300px">
            <select2
              v-model="filter.aoi"
              :items="listAOI"
              :loading="loading"
              dense
              hide-details
              item-text="name"
              item-value="uuid"
              label="Select AOI"
              outlined
              placeholder="AOI"
              return-object
            />
          </div>
          <div v-if="menu !== 'forecast'" class="ml-1 mr-2" style=" min-width: 300px">
            <DateRangerPicker
              v-model="filter.dateRange"
              :max="maxDate"
              :min="minDate"
              dense
              label="Select time"
              left
              outlined
            />
          </div>
        </v-layout>
      </v-card>
    </div>
    <v-layout class="pt-2 overflow-y-hidden" style="border-radius: 8px">
      <v-layout class="fill-height overflow-y-hidden" column style="border-radius: inherit">
        <WeatherMonitoring v-if="menu === 'monitoring'" ref="screenData" />
        <WeatherForecast v-if="menu === 'forecast'" ref="screenData" />
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import Select2 from '@/components/Select2/Select2.vue'
import { mapState } from '@/store/ults'
import WeatherMonitoring from './monitoring/WeatherMonitoring.vue'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import sleep from '@/utils/sleep'
import ConvertDate from '@/utils/convertDate'
import WeatherForecast from './forecast/WeatherForecast.vue'

export default {
  components: { DateRangerPicker, Select2, WeatherMonitoring, WeatherForecast },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
  },
  data() {
    return {
      loading: false,
      firstLoad: true,
      filter: {
        aoi: undefined,
        dateRange: undefined,
      },
      minDate: undefined,
      maxDate: undefined,
      menu: 'monitoring',
    }
  },
  watch: {
    menu(val) {
      if (val === 'monitoring') {
        if (this.filter.aoi && this.filter.dateRange.length === 2) this.getData()
        this.minDate = undefined
        this.maxDate = undefined
      } else this.getData()
    },
    filter: {
      handler: function(newValue) {
        if (newValue.aoi && newValue.dateRange.length === 2) this.getData()
      },
      deep: true,
    },
  },
  mounted() {
    let toDate = new Date()
    toDate.setFullYear(toDate.getFullYear() - 1)
    this.filter.dateRange = [ConvertDate.dateFormatted(new Date()), ConvertDate.dateFormatted(toDate)]
    this.getListAOI()
  },
  methods: {
    async getListAOI() {
      try {
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: {},
        })
        if (this.firstLoad) {
          this.filter.aoi = this.AOIs[0]
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getData() {
      await sleep(200)
      await this.$refs.screenData.getData(this.filter)
    },
  },
}
</script>

<style scoped></style>
