<template>
  <v-layout class="fill-height overflow-y-hidden">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="custom-card-bg-1 pa-2 overflow-y-hidden" height="100%" width="100%">
      <v-layout class="fill-height overflow-y-auto overflow-x-hidden" column>
        <div style="flex: none; width: 100%; min-height: 335px; border-bottom: 3px solid whitesmoke">
          <v-layout class="fill-height pa-3" column>
            <div>
              <v-layout>
                <h1>
                  Weather Forecast
                </h1>
                <v-spacer />
                {{ timezone }}
              </v-layout>
            </div>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-layout class="fill-height">
                  <v-layout class="pt-2" column fill-height>
                    <div>
                      <v-layout align-center>
                        <h3>{{ selectedDate.datetime }}</h3>
                        <v-spacer />
                        <h1>{{ selectedDate.tempmin }}°C/{{ selectedDate.tempmax }}°C</h1>
                      </v-layout>
                    </div>
                    <div class="pt-4 " style="font-size: 26px; border-top: 1px solid whitesmoke"></div>
                    <div>
                      {{ selectedDate.conditions }}
                    </div>
                    <div style="font-size: 14px">
                      <v-layout align-center>
                        Cloud:
                        <span class="pl-2"
                          >{{ selectedDate.cloudcover }}%<v-icon class="ml-1" size="18">mdi-cloud</v-icon></span
                        >
                      </v-layout>
                    </div>
                    <div style="font-size: 14px">
                      <v-layout align-center>
                        Precipitation prob:
                        <span class="pl-2">
                          {{ selectedDate.precipprob }}%<v-icon class="ml-1" size="18">mdi-weather-pouring</v-icon>
                        </span>
                      </v-layout>
                    </div>
                    <div>
                      <v-layout align-center>
                        Humidity:
                        <span class="pl-2"
                          >{{ selectedDate.humidity }}%<v-icon class="ml-1" size="18">mdi-water</v-icon></span
                        >
                      </v-layout>
                    </div>
                    <div>
                      <v-layout align-center>
                        Wind speed:
                        <span class="pl-2">
                          {{ selectedDate.windspeed }} km/h<v-icon class="ml-1" size="18">mdi-weather-windy</v-icon>
                        </span>
                      </v-layout>
                    </div>
                    <div>
                      <v-layout align-center> Other information: {{ selectedDate.description }} </v-layout>
                    </div>
                  </v-layout>
                  <div class="fill-height ml-12">
                    <v-layout align-center class="fill-height" column>
                      <div
                        style="width: 80px; height: 80px; background-size: 100% 100%"
                        v-bind:style="{ 'background-image': `url(${getLinkImage(selectedDate.icon)})` }"
                      />
                      <div style="font-size: 50px">{{ selectedDate.temp }}°C</div>
                    </v-layout>
                  </div>
                </v-layout>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-layout column>
                  <div style="flex: none">
                    <v-layout align-center>
                      <v-icon class="ml-12" color="rgb(203,0,0)" size="32" style="rotate: 45deg">
                        mdi-vector-line
                      </v-icon>
                      <div class="ml-3" style="font-size: 12px">Temp °C</div>
                      <v-icon class="ml-12" color="#116DF2" size="32">mdi-rectangle</v-icon>
                      <div class="ml-3" style="font-size: 12px">Precipitation(mm)</div>
                    </v-layout>
                  </div>
                  <v-layout class="fill-height" column>
                    <canvas
                      id="hourChart"
                      style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"
                    ></canvas>
                  </v-layout>
                </v-layout>
              </v-col>
            </v-row>
          </v-layout>
        </div>
        <div style="width: 100%">
          <v-layout align-center class="wrap pa-4" justify-center style="height: 350px;">
            <SlideGroup style="flex: 1;width:90%" :items="data" :currentItem="selectedDate" multiple>
              <template v-slot:content="{ item, index }">
                <div
                  v-if="item.temp"
                  class="pa-2 my-1"
                  style="flex: none; width: 290px; height:100%; border-bottom: 1px solid whitesmoke; position: relative"
                >
                  <div v-if="selectedDate === item" style="position: absolute; top: 10px; right: 10px; z-index: 1">
                    <v-icon color="#448AFF" size="32">mdi-bookmark-check</v-icon>
                  </div>
                  <div style="flex: none; width: 100%; height:100%; border-radius: 8px">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :class="hover || selectedDate === item ? 'custom-card-bg-2' : 'custom-card-bg-1'"
                        class="pa-4"
                        height="100%"
                        style="cursor: pointer"
                        width="100%"
                        @click="selectedDate = item"
                      >
                        <v-layout class="pt-2" column fill-height>
                          <h4>{{ item.datetime }}</h4>
                          <div>
                            <v-layout align-center>
                              <div
                                style="width: 65px; height: 65px; background-size: 100% 100%"
                                v-bind:style="{ 'background-image': `url(${getLinkImage(item.icon)})` }"
                              ></div>
                              <span class="pl-3" style="font-size: 30px">{{ item.temp }}°C</span>
                            </v-layout>
                          </div>
                          <div class="pt-2" style="font-size: 26px">{{ item.tempmin }}°C/{{ item.tempmax }}°C</div>
                          <div class="pb-1" style="font-size: 16.5px">
                            {{ item.conditions }}
                          </div>
                          <div style="font-size: 14px">
                            <v-layout align-center>
                              Cloud:
                              <span class="pl-2"
                                >{{ item.cloudcover }}%<v-icon class="ml-1" size="18">mdi-cloud</v-icon></span
                              >
                            </v-layout>
                          </div>
                          <div style="font-size: 14px">
                            <v-layout align-center>
                              Precipitation prob:
                              <span class="pl-2">
                                {{ item.precipprob }}%<v-icon class="ml-1" size="18">mdi-weather-pouring</v-icon>
                              </span>
                            </v-layout>
                          </div>
                          <div style="font-size: 14px">
                            <v-layout align-center>
                              Humidity:
                              <span class="pl-2"
                                >{{ item.humidity }}%<v-icon class="ml-1" size="18">mdi-water</v-icon></span
                              >
                            </v-layout>
                          </div>
                          <div style="font-size: 14px">
                            <v-layout align-center>
                              Wind speed:
                              <span class="pl-2"
                                >{{ item.windspeed }} km/h<v-icon class="ml-1" size="18"
                                  >mdi-weather-windy</v-icon
                                ></span
                              >
                            </v-layout>
                          </div>
                        </v-layout>
                      </v-card>
                    </v-hover>
                  </div>
                </div>
              </template>
            </SlideGroup>
          </v-layout>
        </div>
      </v-layout>
    </v-card>
  </v-layout>
</template>
<script>
import { getForecastWeather } from '@/api/weather'
import config from '@/config'
import { transparentize } from '@/utils/utils'
import Chart from 'chart.js/auto'
import SlideGroup from '@/views/object-detection/components/SlideGroup.vue'

export default {
  data() {
    return {
      loading: false,
      selectedDate: {},
      data: [],
      hourChart: undefined,
      labels: [],
      chartData: [],
      timezone: undefined,
    }
  },
  components: { SlideGroup },
  watch: {
    selectedDate() {
      if (this.hourChart)
        this.updateChart(this.selectedDate.hours, 'hourChart', [
          {
            index: 'temp',
            color: 'rgb(203,0,0)',
            tension: 0.4,
            radius: 0,
            label: 'Temp',
            fill: true,
            type: 'line',
          },
          {
            index: 'precip',
            color: '#116DF2',
            tension: 0.4,
            radius: 0,
            label: 'Precipitation',
            fill: true,
            yAxisID: 'y1',
            type: 'bar',
          },
        ])
      else
        this.createChart(this.selectedDate.hours, 'hourChart', [
          {
            index: 'temp',
            color: 'rgb(203,0,0)',
            tension: 0.4,
            radius: 0,
            label: 'Temp',
            fill: true,
            type: 'line',
          },
          {
            index: 'precip',
            color: '#116DF2',
            tension: 0.4,
            radius: 0,
            label: 'Precipitation',
            fill: true,
            yAxisID: 'y1',
            type: 'bar',
          },
        ])
    },
  },
  methods: {
    async getData(filter) {
      try {
        this.loading = true
        const res = await getForecastWeather({
          projectId: this.$route.params.id,
          aoiId: filter.aoi.uuid,
        })
        this.data = res.data.data
        this.timezone = res.data.timezone
        this.selectedDate = this.data[0]
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    updateChart(data, id, indies) {
      this[id].data.labels = data.map(val => val.datetime)
      let datasets = []
      indies.forEach(item => {
        if (item.type === 'line') {
          datasets.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if (item.type === 'bar') {
          datasets.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: transparentize(item.color, 0.2),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })

      this[id].data.datasets = datasets
      this[id].update()
      this[id].resize()
    },
    createChart(data, id, indies) {
      this.transformData(data, indies)
      this.displayChart(id)
    },
    transformData(data, indies) {
      this.labels = data.map(val => val.datetime)
      this.chartData = []
      indies.forEach(item => {
        if (item.type === 'line') {
          this.chartData.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if ((item.type = 'bar')) {
          this.chartData.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: transparentize(item.color, 0.2),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
    },
    displayChart(id) {
      let ctx = document.getElementById(id)
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      this[id] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index', // Set hover mode to 'nearest'
            // axis: 'x',
          },
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: false,
              position: window.innerHeight > 350 ? 'right' : 'top',
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9,
                },
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              offset: true,
              offsetAmount: 50,
              title: {
                color: 'white',
                text: '- Temp ( °C ) -',
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9.5,
                },
              },
            },
            y1: {
              type: 'linear',
              position: 'right',
              offset: true,
              offsetAmount: 50,
              title: {
                color: 'white',
                text: '- Precipitation ( mm ) -',
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9.5,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    getLinkImage(icon) {
      return config.backend_pub + '/weather-icons/dark-mode/' + icon + '.png'
    },
  },
}
</script>

<style scoped>

</style>
