<template>
  <v-layout class="fill-height overflow-y-auto pa-3" column>
    <div>
      <v-layout align-center>
        <h4>Daily temperatures, °C</h4>
        <v-icon class="ml-12" color="rgb(68 234 255)" size="32" style="rotate: 45deg">
          mdi-vector-line
        </v-icon>
        <div class="ml-3" style="font-size: 12px">Temp min (°C)</div>
        <v-icon class="ml-8" color="#E040FB" size="32" style="rotate: 45deg">mdi-vector-line </v-icon>
        <div class="ml-3" style="font-size: 12px">Temp max (°C)</div>
        <v-icon class="ml-12" color="#116DF2" size="32" style="rotate: 45deg">mdi-vector-line </v-icon>
        <div class="ml-3" style="font-size: 12px">Dew point (°C)</div>
      </v-layout>
    </div>
    <div
      class="my-4 pa-3"
      style="flex: none; width: 100%; min-height: 300px;height: 300px; border: 1px solid #2F3241; border-radius: 8px"
    >
      <canvas id="dailyChart" style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"></canvas>
    </div>
    <h4>Sum of Active Temperatures, °C</h4>
    <div
      class="mt-4 pa-3"
      style="flex: none; width: 100%; min-height: 300px;height: 300px; border: 1px solid #2F3241; border-radius: 8px"
    >
      <canvas id="soatChart" style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"></canvas>
    </div>
  </v-layout>
</template>

<script>
import Chart from 'chart.js/auto'
import { transparentize } from '@/utils/utils'

export default {
  data() {
    return {
      dailyChart: undefined,
      soatChart: undefined,
      labels: [],
      chartData: [],
    }
  },
  methods: {
    updateChart(data, id, indies) {
      this[id].data.labels = data.map(val => val.datetime)
      let datasets = []
      indies.forEach(item => {
        datasets.unshift({
          type: 'line',
          label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
          data: data.map(val => val[item.index]),
          fill: item.fill,
          tension: item.tension,
          pointRadius: item.radius,
          pointHoverRadius: 4,
          backgroundColor: transparentize(item.color, 0.5),
          borderColor: item.color,
          yAxisID: 'y',
        })
      })

      this[id].data.datasets = datasets
      this[id].update()
      this[id].resize()
    },
    createChart(data, id, indies, tension, radius) {
      this.transformData(data, indies, tension, radius)
      this.displayChart(id)
    },
    transformData(data, indies) {
      this.labels = data.map(val => val.datetime)
      this.chartData = []
      indies.forEach(item => {
        this.chartData.unshift({
          type: 'line',
          label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
          data: data.map(val => val[item.index]),
          fill: item.fill,
          tension: item.tension,
          pointRadius: item.radius,
          pointHoverRadius: 4,
          backgroundColor: transparentize(item.color, 0.5),
          borderColor: item.color,
          yAxisID: 'y',
        })
      })
    },
    displayChart(id) {
      let ctx = document.getElementById(id)
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      this[id] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index', // Set hover mode to 'nearest'
            // axis: 'x',
          },
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9,
                },
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              offset: true,
              offsetAmount: 50,
              title: {
                color: 'rgb(68 234 255)',
                text: '- Temp ( °C ) -',
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: 'rgb(68 234 255)',
                font: {
                  size: 9.5,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style scoped></style>
